import React from 'react';
import './Hero.css';
import HeroVid from '../../assets/vid1.mp4';

const Hero = () => {
  return (
    <div className='hero-container'>
      <video className='object-cover h-full w-full absolute mt-[-90px] -z-50' src={HeroVid} autoPlay loop muted />
      <div className="hero-content-container">
        <div className="hero-content">
          <h1>Decentralize</h1>
          <h2><span className='text-[#8800C8]'>Trading</span> Protocol</h2>
          <p>Based on the Ethereum block chain technology</p>
          <div className='button-container mt-16'>
            <button className='sign-up-button hover:scale-110'>Get Started</button>
            <button className='learn-more-button hover:scale-110 ml-8'>Learn More</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero