import React, {useState, useEffect, useRef} from 'react';
import './Navbar.css';
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai';
import { NavLink, Link } from 'react-router-dom';

const Navbar = () => {
    const [menu, setMenu] = useState(false);
    const ref = useRef();

    useEffect( () => {
        const checkIfClickedOutside = e => {
          if ( menu && ref.current && !ref.current.contains(e.target)) {
            setMenu(false);
            }
        };
            
        document.addEventListener('mousedown', checkIfClickedOutside)
    
        return () => {
              document.removeEventListener('mousedown', checkIfClickedOutside)
        }
    
      }, [menu]); 

    return (
    <div className='navbar-container'>
        <div className='inner-container'>
            <h1 className='logo-header'>
                <Link to="/">NeuFi</Link>
            </h1>
        <div className='right-container'>
            <ul className='items-container'>
                <NavLink to="/"><li>Platform</li></NavLink>
                <NavLink to="developers"><li>Developers</li></NavLink>
                <NavLink to="community"><li>Community</li></NavLink>
                <NavLink to="about"><li>About</li></NavLink>
            </ul>
            <button className='primary-button'>Sign Up</button>
        </div>

    {/* Hamburger Icon */}
        <div className='mobile-icons-container'>
            { menu 
                ? <AiOutlineClose onClick={() => setMenu(false)}/> 
                : <AiOutlineMenu onClick={() => setMenu(true)}/> 
            }
        </div>
    {/* Mobile Menu */}
    { menu && (
        <div className='mobile-menu-container' ref={ref}>
            <ul className='mobile-items-container'>
                <NavLink to="/"><li>Platform</li></NavLink>
                <NavLink to="developers"><li>Developers</li></NavLink>
                <NavLink to="community"><li>Community</li></NavLink>
                <NavLink to="about"><li>About</li></NavLink>
            </ul>
            <div className='grid justify-center text-xl mt-6'>
                <button className='primary-button hover:scale-110'>Sign Up</button>
            </div>
        </div>
        )}
        </div>
    </div>
  )
}
  
export default Navbar