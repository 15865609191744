import React from 'react';
import './Join.css';

const Join = () => {
  return (
    <div className='join-container'>
        <div className='join-content-container'>
            <h1 className='join-header'>Enter your email to get started</h1>
            <div className='subscribe-container'>
                <input type='email' placeholder='Enter your email' className='email-input'></input>
                <button className='sign-up-button text-white text-lg hover:scale-110 md:ml-4 mt-8 px-12'>Join</button>
            </div>
            <div>
                <ul className='disclaimer-items'>
                    <li><input type="checkbox" className='checkbox' /></li>
                    <li><p className='text-left'>Yes, I agree to recieve email communication from NeuFi.</p></li>
                </ul>
            </div>
        </div>
    </div>
  )
}

export default Join