import React from 'react';
import '../App.css';
import '../assets/bg1.png';
import '../assets/img-2.jpg';

const Developers = () => {
  return (
    <div className='developers-container'>
        <div className='developers-content-container'>
            <div className='developers-grid-container'>
            <div className='developers-right-container'>
                <p className='text-lg'>Consistent with its decentralized nature, NueFi is 
                continuing to work with a broader range of development teams. To assist in this, in 
                the first half of 2019 we are opening up the bidding process for work to interested 
                outside parties, accepting proposals from teams for tasks of all types, including not 
                only development of future districts and modules, but also administrative, marketing, or 
                community management tasks.</p>
            </div>
            <div className='developers-left-container'>
                <img src={require('../assets/img-2.jpg')} alt="developers" className='img2-style'/>
            </div>
            </div>
        </div>

    </div>
  )
}

export default Developers