import React from 'react';
import '../App.css';

import Hero from '../components/Hero/Hero';
import Info from '../components/info/Info';
import Community from '../components/Community/Community';
import Join from '../components/Join/Join';

const Home = () => {
  return (
    <div className='App'>
        <Hero />
        <Info />
        <Community />
        <Join />
    </div>
  )
}

export default Home