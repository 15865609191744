import React from 'react';
import './Community.css';
import ImageOne from '../../assets/img-1.jpg';

const Community = () => {
  return (
    <div className='community-container'>
        <div className='community-content-container'>
            <h1 className='text-5xl my-6'>Power to the community</h1>
            <p className='md:w-[800px]'>Get the <span>documentation</span>, and the <span>quick start</span> guide to start integrating your project with thousands of decentralize users around the world.</p>
            <div className='image-container'>
                <img src={ImageOne} alt="possibilities" className='community-image-style'/>
            </div>
        </div>
    </div>
  )
}

export default Community