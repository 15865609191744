import React from 'react';
import '../assets/Circle.svg';
import '../App.css';

const Circle = () => {
    return (
    <div className='circle-container'>
        <svg width="900" height="800" viewBox="0 0 500 500" fill="none">
        <g id="Circle">
            <g id="CircleGroup">
                <circle id="circle3" cx="291" cy="250" r="147" />
                <circle id="circle2" cx="209" cy="250" r="147" />
                <circle id="circle1" cx="254" cy="250" r="147" />
            </g>
        </g>
        </svg>
    </div>
  )
}

export default Circle