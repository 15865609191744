import React from 'react';
import './Info.css';
import InfoCard from './InfoCard';
import {BiDiamond, BiShield, BiShareAlt} from 'react-icons/bi';

const infoData = [
 {
    id: 1,
    icon: <BiDiamond />,
    title: "Reliable, tamperproof network",
    info: "With the use of decentralization, trusted block chain technology, Ethereum, backed by major companies such as Microsoft, Google and Apple."    
 },
 {
    id: 2,
    icon: <BiShareAlt />,
    title: "Seamless connection to any API",
    info: "Build on a flexible framework that can retrieve data from any API, connect with existing systems, and integrate with any current or future block chain."    
 },
 {
    id: 3,
    icon: <BiShield />,
    title: "Secure off-chain computation technology",
    info: "The use of ethereum block chain technology, and Ethereum contracting technology, the network of decentralize network uses nodes AS2 encryption to automate contracts, mitigating risk of manual interventions."    
 }
]


const Info = () => {
  return (
    <div className='info-container'>
        <div className='info-content-container'>
            <h1 className='info-header'>A Growing Protocol Ecosystem</h1>
            <h3>The protocol for the Ethereum block chain technology empowers developers, liquidity providers, and traders to participate in a financial marketplace that is open and accessible to all.</h3>
            <div className='info-card-container'>
               {infoData.map((item) =>
                <InfoCard key={item.id} icon={item.icon} title={item.title} info={item.info}/>
               )}
            </div>
        </div>
    </div>
  )
}

export default Info