import React from 'react';
import './Footer.css';
import FooterCard from './FooterCard';
import {SiFacebook, SiTwitter, SiInstagram} from 'react-icons/si';

const footerData = [
  {
    id: 1,
    header: 'Products',
    item1: 'Token',
    item2: 'App',
    item3: 'Analytics',
    item4: 'Network'
  },
  {
    id: 2,
    header: 'Services',
    item1: 'Security',
    item2: 'Development',
    item3: 'Cloud',
    item4: 'Artificial Intelligence'
  },
  {
    id: 3,
    header: 'General',
    item1: 'About',
    item2: 'Careers',
    item3: 'Contact',
    item4: 'Terms'
  },
]

const Footer = () => {
  return (
    <div className='footer-container'>
      <div className='footer-content-container'>
        { footerData.map((item) =>
        <FooterCard key={item.id} header={item.header} item1={item.item1} item2={item.item2} item3={item.item3} item4={item.item4} />
       )}
      </div>
      <div className='text-center flex p-2'>
        <SiFacebook size={30} className="mr-6"/>
        <SiTwitter size={30} className='mr-6'/>
        <SiInstagram size={30} />
      </div>
      <div className='text-center mt-[50px] mb-[25px]'>
        <h1>Copyright 2022 NeuFi Inc.  |  All Rights Reserve </h1>
      </div>
    </div>
  )
}

export default Footer