import './App.css';
import { Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import Home from './pages/Home';
import Developers from './pages/Developers';
import Community from './pages/Community';
import About from './pages/About';


function App() {
  return (
    <>
    <Navbar />
      <Routes>
        <Route index element={<Home />} />
        <Route path='developers' element={<Developers />} />
        <Route path='community' element={<Community />} />
        <Route path='about' element={<About />} />
      </Routes>
    <Footer />
    </>
  );
}

export default App;
