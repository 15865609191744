import React from 'react';
import './InfoCard.css'

const InfoCard = ({icon, title, info}) => {
  return (
    <div className='info-card'>
        <div className='icon-container'>
            <div className='icon-bg'>
            {icon}
            </div>
        </div>
        <div className='title'>
            <h1>{title}</h1>
        </div>
        <div className='info'>
            <p>{info}</p>
        </div>
    </div>
  )
}

export default InfoCard