import React from 'react';
import './FooterCard.css'

const FooterCard = ({header, item1, item2, item3, item4}) => {
  return (
    <div className='footer-card-container'>
        <div className='footer-card-content'>
            <h1 className='footer-header'>{header}</h1>
            <ul className='footer-items'>
                <li>{item1}</li>
                <li>{item2}</li>
                <li>{item3}</li>
                <li>{item4}</li>
            </ul>
        </div>
    </div>
  )
}

export default FooterCard