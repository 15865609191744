import React from 'react';
import Circle from './Circle';
import '../assets/Circle.svg';
import { FaFacebookF, FaTwitter, FaInstagram } from 'react-icons/fa';



const Community = () => {
  return (
    <div className='community-page-container'>
        <Circle />
        <div className='text-center'>
            <div className='inline-flex text-white'>
                <FaFacebookF className="m-4"size={50} />
                <FaTwitter className="m-4" size={50} />
                <FaInstagram className="m-4" size={50} />
            </div>
        </div>
    </div>
  )
}

export default Community