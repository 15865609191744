import React from 'react';
import '../App.css';

const About = () => {
  return (
    <div className='about-container'>
        <div className='about-content-container'>
            <div className='about-page-content text-lg'>
                <p>NueFi (“decentralized finance”) is an umbrella term for financial services on 
                public blockchains, primarily Ethereum. With NueFi, you can do most of the things that banks 
                support — earn interest, borrow, lend, buy insurance, trade derivatives, trade assets, 
                and more — but it’s faster and doesn’t require paperwork or a third party. As with crypto 
                generally, NueFi is global, peer-to-peer (meaning directly between two people, not routed through 
                a centralized system), pseudonymous, and open to all. </p><br/>
                <p>
                NueFi takes the basic premise of Bitcoin — digital money — and expands on it, creating an 
                entire digital alternative to Wall Street, but without all the associated costs (think office towers, 
                trading floors, banker salaries). This has the potential to create more open, free, and fair financial
                markets that are accessible to anyone with an internet connection.
                </p>
            </div>
        </div>

    </div>
  )
}

export default About